/*	------------------------------------------------------------------------------
	FONTS
	------------------------------------------------------------------------------ */
@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400,700&display=swap");
@font-face {
  font-family: akro-thin;
  src: url("../webfonts/akrobat-thin-webfont.woff");
}
@font-face {
  font-family: akro-regular;
  src: url("../webfonts/akrobat-regular-webfont.woff");
}
@font-face {
  font-family: akro-bold;
  src: url("../webfonts/akrobat-bold-webfont.woff");
}
@font-face {
  font-family: akro-thin;
  src: url("../webfonts/akrobat-thin-webfont.woff");
}
@font-face {
  font-family: akro-extra-bold;
  src: url("../webfonts/akrobat-extrabold-webfont.woff");
}
@font-face {
  font-family: akro-semi-bold;
  src: url("../webfonts/akrobat-semibold-webfont.woff");
}
/* -------------------------------------------------------------------------------
    Variables
   ------------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------------
    HTML Elements
   ------------------------------------------------------------------------------- */
body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  position: relative;
  min-height: 100.1%;
  background-color: #000;
  overflow-x: hidden;
  font-family: akro-regular, 'sans-serif';
}
h1,
.h1 {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
}
h2,
.h2,
h3,
.h3,
blockquote,
th {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
}
h3 {
  color: #f58428;
  font-size: 35px;
  z-index: 11;
  letter-spacing: 4px;
}
a,
button {
  transition: all 0.3s;
}
:focus,
:visited,
:hover,
:active,
a:focus,
a:visited,
a:hover,
a:active,
button:focus,
button:hover {
  outline: 0;
  text-decoration: none;
  box-shadow: none !important;
}
p {
  font-size: 14px;
}
/* -------------------------------------------------------------------------------
    Custom Helpers
   ------------------------------------------------------------------------------- */
.bg {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.bg-dark {
  background-color: #000 !important;
}
.bg-light {
  background-color: #0b191c !important;
}
.shadow {
  box-shadow: 2px 2px 8px 0px rgba(0,0,0,0.1);
}
.overlay {
  display: none;
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0.8);
}
.image-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0.5);
}
.image-frame {
  background-color: #fff;
  border: 1px solid rgba(0,0,0,0.125);
  border-radius: 0.25rem;
  padding: 5px;
}
.center-absolute-x {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
}
.center-absolute-y {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}
.center-absolute-xy {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}
.center-block-x {
  margin-left: auto !important;
  margin-right: auto !important;
}
.center-flex-y {
/* add class to parent of centred content */
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.center-flex-xy {
/* add class to parent of centred content */
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn.btn-clear {
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
  padding: 15px;
  width: 200px;
  height: 50px;
  border: 1px solid #fff;
  border-radius: 80px;
  font-weight: 500;
  font-size: 16px;
  margin-top: 50px;
  font-family: akro-bold, 'sans-serif';
}
.btn.btn-clear:hover {
  border-color: #f58428;
  color: #f58428;
}
.more-btn {
  background-repeat: no-repeat;
  background-image: url("../img/more-btn.png");
  background-size: 100%;
  display: inline-block;
}
.icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.icon.icon-logo {
  background-image: url("../img/icon-logo.png");
  height: 100px;
  width: 75px;
}
.icon.icon-close {
  background-image: url("../img/icon-close.svg");
  height: 45px;
  width: 45px;
}
/* -------------------------------------------------------------------------------
    Components
   ------------------------------------------------------------------------------- */
.modal-content {
  background-color: #12272c;
}
.modal-content .modal-header {
  padding-bottom: 0;
}
.modal-content h3 {
  margin-bottom: 50px;
}
.modal-content .modal-header {
  border-bottom: none;
}
.modal-content .btn-clear {
  width: 138px;
  height: 50px;
}
.modal-content input,
.modal-content textarea {
  background-color: #12272c;
  border: 1px solid #5c5c5c;
  border-radius: 50px;
  padding: 15px 25px;
}
.modal-content textarea {
  height: 200px;
  border-radius: 20px;
}
.page {
  min-height: 100vh;
}
.component {
  position: relative;
}
.component,
.spaced {
  padding-top: 3em;
  padding-bottom: 3em;
}
.component {
  padding-top: 0;
}
.component-carousel,
.component-header {
  padding-top: 0;
  paddign-bottom: 0;
}
.component-header .header-text {
  z-index: 2;
  top: 50%;
}
/* Carousel & Slider */
.text-with-image .slider {
  margin-bottom: 50px;
}
.text-with-image .slick-slide {
  padding: 0 3em;
  height: 20vw;
  transition: 0.3s ease all;
}
.text-with-image .slick-slide .image .content {
  padding: 20px;
  width: 42%;
  height: 315px;
  opacity: 0;
  transition: 0.3s ease all;
  position: absolute;
  background: #12272c;
  top: 0;
  right: 0;
  overflow: hidden;
  transform: translate(10px, -10px);
  box-shadow: 0 0 20px #000 !important;
}
.text-with-image .slick-slide .image .content .btn {
  transform: scale(0.6);
  transform-origin: left;
}
.text-with-image .slick-slide .image .content .btn:hover {
  color: #f58428;
  border-color: #f58428;
}
.text-with-image .slick-slide .image .content h4 {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 1.4;
  font-family: 'Roboto Condensed', sans-serif;
  color: #fff;
}
.text-with-image .slick-slide .image .content p {
  font-size: 0.7rem;
  color: #fff;
}
.text-with-image .slick-list {
  margin: 0;
  overflow: visible;
  margin-top: 0;
  margin-bottom: 0;
  overflow: hidden;
  height: 472px;
  padding-top: 79px;
}
.text-with-image .slick-current {
  transform: scale(1.5);
  transform-origin: center center;
}
.text-with-image .slick-current .image .content {
  opacity: 1;
}
.text-only-slide {
  padding-top: 3em;
}
.text-only-slide h4 {
  color: #f58428;
  font-size: 30px;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}
.text-only-slide p {
  color: #fff;
  font-weight: 400;
  font-size: 16px;
}
.text-only-slide .foot-note p {
  color: #f58428;
  font-size: 18px;
  font-style: italic;
}
.text-only-slide .carousel-indicators {
  bottom: -20px !important;
}
.text-only-slide .carousel-indicators li.slick-active {
  background: #f58428;
}
.carousel-item,
.component-header {
  height: 50vw;
  overflow: hidden;
}
.component-home {
  height: 800px;
  background-size: cover;
  background-position-x: left;
}
.carousel-control-next,
.carousel-control-prev {
  opacity: 0.4;
  transition: 0.3s ease opacity;
  z-index: 2;
}
.carousel-control-next.slick-disabled,
.carousel-control-prev.slick-disabled,
.carousel-control-next.disabled,
.carousel-control-prev.disabled {
  opacity: 0 !important;
  cursor: default;
}
.carousel-control-next:focus,
.carousel-control-prev:focus {
  opacity: 0.6;
}
.carousel-control-next:hover,
.carousel-control-prev:hover {
  opacity: 1;
}
.carousel-control-next .carousel-control-next-icon,
.carousel-control-prev .carousel-control-next-icon,
.carousel-control-next .carousel-control-prev-icon,
.carousel-control-prev .carousel-control-prev-icon {
  width: 20px;
  height: 20px;
}
.carousel-indicators {
  transform: translateY(30px);
}
.carousel-indicators li {
  width: 12px;
  height: 12px;
  opacity: 1;
  transition: 0.3s ease all;
  cursor: pointer;
  border-radius: 50%;
  background: #525c5e;
}
.carousel-indicators li.active,
.carousel-indicators li.slick-active {
  background: #f58428;
}
.carousel-indicators li:hover {
  background: rgba(255,255,255,0.8);
}
.slider-mode-text .slick-slide {
  padding: 0;
  text-align: center;
}
.slider-mode-text .carousel-indicators {
  bottom: -25px;
}
.slider-mode-text .carousel-control-prev {
  left: -10%;
}
.slider-mode-text .carousel-control-next {
  right: -10%;
}
@media only screen and (max-width: 768px) {
  .slider-mode-text .carousel-control-prev {
    left: 0%;
  }
  .slider-mode-text .carousel-control-next {
    right: 0%;
  }
}
/* Cards / Portfolio */
.card .card-body .card-thumbnail {
  max-height: 140px;
  overflow: hidden;
}
.card .card-body .card-text {
  height: 100px;
  overflow: hidden;
}
.component-image-text h3,
.component-text-image h3 {
  text-transform: uppercase;
  margin-bottom: 15px;
  margin-top: 15px;
}
.component-image-text h4,
.component-text-image h4 {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
  font-size: 18px;
  color: #f58428;
}
.component-image-text p,
.component-text-image p {
  font-size: 14px;
}
.component-image-text .text,
.component-text-image .text {
  padding-bottom: 100px;
  padding-left: 30px;
  padding-right: 45px;
}
.component-image-text .image,
.component-text-image .image {
  background-size: 100%;
  background-repeat: no-repeat;
}
.page-template-home .component-image-text .image,
.page-template-home .component-text-image .image {
  background-size: cover;
}
.page-template-default .header-text .header-title-wrapper :after {
  bottom: 165px !important;
}
.component-image-text .more-btn,
#about .more-btn {
  width: 40px;
  height: 40px;
  margin-top: 50px;
  position: absolute;
  z-index: 14;
}
.component-text-image .component-inner {
  position: relative;
}
.component-text-image .component-inner >.row:before {
  content: "";
  position: absolute;
  width: 90%;
  height: 100%;
  right: 0;
  top: 0;
  background-color: #12272c;
}
.component-text-image .more-btn {
  width: 40px;
  height: 40px;
  margin-top: 50px;
  position: absolute;
  z-index: 11;
}
.component-services-blocks p {
  font-size: 14px;
}
.component-services-blocks .main-wrapper:after {
  position: absolute;
  width: 79%;
  height: 90%;
  background-color: #12272c;
  content: "";
  right: 0;
  top: -170px;
  z-index: 1;
}
.component-services-blocks .main-wrapper .inner-content {
  position: relative;
  z-index: 11;
  bottom: 135px;
}
.component-services-blocks h3 {
  z-index: 11;
}
.component-services-blocks .block {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 30px;
  padding-top: 30px;
}
.component-services-blocks .block .light {
  background-color: #fff;
}
.component-services-blocks .block .light h3 {
  color: #11272b;
}
.component-services-blocks .block .light p {
  color: #11272b;
}
.component-services-blocks .block .dark {
  background-color: #000;
  position: relative;
}
.component-services-blocks .block .dark p {
  color: #f58428;
  z-index: 11;
}
.component-services-blocks .block .dark:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background-color: rgba(0,0,0,0.6);
}
.component-services-blocks .block h3 {
  font-size: 25px;
  text-align: center;
}
.component-services-blocks .block p {
  text-align: center;
}
.component-services-blocks .block .inner-block {
  min-height: 333px;
  max-height: 333px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 40px;
  background-size: cover;
}
.component-services-blocks .btn-clear {
  margin-right: 80px;
}
.component-slider {
  z-index: 13;
}
.component-slider h3 {
  z-index: 11;
}
.component-slider a {
  color: #f58428;
}
#about .image {
  background-size: cover;
  min-height: 500px;
}
/* -------------------------------------------------------------------------------
    Style Bootstrap Classes
   ------------------------------------------------------------------------------- */
.btn,
a.btn,
input.btn,
button.btn {
  font-family: akro-regular, 'sans-serif';
  font-weight: 700;
}
@media only screen and (min-width: 992px) {
  #nav {
    height: 60px;
  }
}
.subnav {
  top: 60px;
  z-index: 1020;
}
.has-subnav {
  margin-top: 60px;
}
.navbar-nav > li:first-child > a.nav-link {
  padding-left: 0px;
}
.navbar {
  backface-visibility: hidden;
  font-family: akro-regular, 'sans-serif';
  font-weight: 500;
}
.navbar .dropdown-menu li {
  width: 100%;
  margin-left: 0 !important;
}
/* -------------------------------------------------------------------------------
    Footer
   ------------------------------------------------------------------------------- */
.result strong {
  color: #f58428;
}
#footer {
  margin-top: 0;
  padding: 40px 0px;
  min-height: 250px;
  font-size: 90%;
  -webkit-font-smoothing: subpixel-antialiased;
  background: rgba(125,125,125,0.2);
  position: relative;
  z-index: 13;
  background-image: url("../img/brand-bg.png");
  background-repeat: no-repeat;
  background-position: 850px 400px;
  background-size: 539px;
}
#footer li {
  margin-bottom: 5px;
}
#footer .fab {
  color: #666;
  margin-right: 5px;
}
#footer h3 {
  text-transform: uppercase;
  color: #f58428;
  margin-bottom: 30px;
}
#footer p {
  font-size: 14px;
  color: #fff;
}
#footer input,
#footer textarea {
  background-color: #000;
  border: 1px solid #5c5c5c;
  border-radius: 50px;
  padding: 15px 25px;
  color: #fff;
}
#footer input::placeholder,
#footer textarea::placeholder {
  text-transform: uppercase;
  font-size: 14px;
}
#footer textarea {
  height: 200px;
  border-radius: 20px;
}
#footer .btn-clear {
  width: 138px;
  height: 50px;
  padding: 10px;
  font-weight: 400;
  border-radius: 40px;
  margin-top: 10px;
}
#footer .info-wrapper {
  margin-top: 40px;
}
#footer .info-wrapper .info {
  color: #f58428;
  margin-top: 25px;
}
#footer .info-wrapper .info a {
  color: #f58428;
  transition: all 0.3s;
}
#footer .info-wrapper .info a:hover {
  opacity: 0.7;
}
#footer .info-wrapper .info .icon-phone {
  background-image: url("../img/icon-phone.png");
  width: 45px;
  height: 45px;
}
#footer .info-wrapper .info .icon-location {
  background-image: url("../img/icon-location.png");
  width: 45px;
  height: 45px;
}
#footer .info-wrapper .info .icon-mail {
  background-image: url("../img/icon-mail.png");
  width: 45px;
  height: 45px;
}
#footer .info-wrapper .info .text {
  margin-left: 20px;
}
#footer .footer-nav {
  margin-top: 50px;
}
#footer .footer-nav li {
  font-size: 14px;
  color: #fff;
}
#footer .footer-nav li a {
  color: #fff;
  text-transform: uppercase;
  padding: 0 10px;
}
#footer .footer-nav li a:hover {
  color: #f58428;
}
#footer .footer-nav li:after {
  content: "|";
  width: 5px;
  height: 5px;
  margin-left: 5px;
  margin-right: 5px;
}
#footer .footer-nav li:first-child {
  margin-left: 0;
}
#footer .footer-nav li:last-child:after {
  content: none;
}
#footer .social-media {
  margin-top: 50px;
}
#footer .social-media li {
  padding-left: 10px;
}
#footer .social-media a:hover .icon-twitter {
  background-image: url("../img/icon-twitter-hover.png");
}
#footer .social-media a:hover .icon-linkedin {
  background-image: url("../img/icon-linkedin-hover.png");
}
#footer .social-media i {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background-color: rgba(17,39,43,0.8);
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: center;
}
#footer .social-media i.icon-twitter {
  background-image: url("../img/icon-twitter.png");
  transition: all 0.3s;
}
#footer .social-media i.icon-linkedin {
  background-image: url("../img/icon-linkedin.png");
  transition: all 0.3s;
}
/* -------------------------------------------------------------------------------
    Forms
   ------------------------------------------------------------------------------- */
.custom-control label {
  font-family: akro-regular, 'sans-serif';
}
input,
select,
textarea {
  font-family: akro-regular, 'sans-serif';
  font-weight: 500;
}
.form-control {
  box-shadow: none !important;
}
.is-invalid {
  border-color: #f58428 !important;
}
.result {
  margin-top: 20px;
  margin-bottom: 40px;
  display: none;
}
.result :first-child {
  margin-top: 0;
  padding-top: 0;
}
.parsley-errors-list {
  margin: 0;
  padding: 0;
  list-style: none;
  color: #f00;
  display: block;
  overflow: hidden;
  font-size: 12px;
  padding-left: 23px;
}
.parsley-errors-list li {
  color: #f58428;
}
.parsley-error,
:focus.parsley-error {
  border-color: #f00;
}
.form-control::-webkit-input-placeholder {
  color: #999;
}
.form-control:-moz-placeholder {
  color: #999;
}
.form-control::-moz-placeholder {
  color: #999;
}
.form-control:-ms-input-placeholder {
  color: #999;
}
.form-control.placeholder {
  color: #999;
}
/* -------------------------------------------------------------------------------
    Navigation
   ------------------------------------------------------------------------------- */
#admin-sidebar {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  z-index: 11;
  border-left: 1px solid #f58428;
  padding-top: 20px;
  background-color: transparent;
}
.admin-sidenav {
  width: 100%;
  height: auto;
  margin-left: 0px;
  display: block;
  position: fixed;
  z-index: 13;
  right: 0;
  top: 70px;
  padding-left: 13px;
}
.admin-sidenav a {
  text-decoration: none;
}
.admin-sidenav li {
  text-align: justify;
  padding: 0.5rem;
  padding-left: 1rem;
  transition: all 0.2s linear;
}
.admin-sidenav li a {
  color: #fff;
  transition: all 0.2s linear;
  font-size: 14px;
  font-family: akro-semi-bold;
}
.admin-sidenav li a:hover {
  color: #f58428;
  font-weight: 700;
  font-family: akro-extra-bold;
}
.admin-sidenav li .active {
  color: #f58428;
  font-weight: 700;
  font-family: akro-extra-bold;
}
header.mobile-nav nav ul {
  display: block;
  text-align: center;
  padding: 25px 40px;
}
header.mobile-nav nav li {
  display: inline-block;
  padding: 0 20px;
  font-size: 16px;
  letter-spacing: 2.5px;
}
header.mobile-nav nav li a {
  color: #28a9e1;
  text-decoration: none;
}
header.mobile-nav .menu-button {
  float: right;
  position: fixed;
  top: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  background: url("../img/menu-btn.png") no-repeat center;
  background-size: 85% 85%;
  background-position-x: center;
  background-color: #000;
  z-index: 1111;
  cursor: pointer;
}
.instruction {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media all and (min-width: 991px) {
  header.mobile-nav .menu-button {
    display: none;
  }
}
@media all and (max-width: 991px) {
  header.mobile-nav nav {
    visibility: hidden;
  }
  .show-menu-button {
    display: inline;
  }
  .instruction {
    display: none;
  }
}
.overlay {
  display: none;
  width: 100%;
  height: auto;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #000;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 2000;
}
.overlay nav {
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.overlay nav ul {
  list-style: none;
}
.overlay ul li {
  padding: 20px;
  text-align: center;
}
.overlay li a {
  text-decoration: none;
  color: #fff;
}
.overlay .close-button {
  position: absolute;
  top: -60px;
  right: -60px;
  float: right;
  background: url("../img/close-btn.png") no-repeat center;
  background-size: 15% 15%;
  width: 200px;
  height: 200px;
}
/* 	-------------------------------------------------------------------------------
	Header
	------------------------------------------------------------------------------- */
.btn {
  color: #fff;
  padding: 15px;
  width: 199px;
  height: 50px;
  border: 1px solid #fff;
  border-radius: 80px;
  font-weight: 500;
  font-size: 16px;
}
.header-text {
  color: #f58428;
  padding-top: 40px;
  padding-right: 165px;
  height: 90vh;
}
.header-text .quote-btn-wrapper {
  position: relative;
}
.header-text .quote-btn-wrapper :after {
  content: "";
  position: absolute;
  width: 100%;
  height: 200%;
  bottom: -10px;
  right: -112px;
  background-image: url("../img/brand-bg.png");
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 500px;
}
.header-text .quote-btn-wrapper .btn {
  margin-top: 0;
  position: relative;
  z-index: 10;
}
.header-text .header-title-wrapper {
  height: 50vh;
}
.header-text .header-title-wrapper h1 {
  font-size: 50px;
  margin-left: 54px;
  letter-spacing: 5px;
}
.header-text .header-title-wrapper :after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 100px;
  left: 0;
  background-image: url("../img/brand-bg.png");
  background-repeat: no-repeat;
  background-position: left 390px;
  background-size: 500px;
}
.header-text .more-btn {
  width: 40px;
  height: 40px;
  margin-left: 260px;
  margin-top: 0px;
  position: relative;
  z-index: 11;
}
/* -------------------------------------------------------------------------------
    Media Queries
   ------------------------------------------------------------------------------- */
@media only screen and (min-width: 992px) {
  .modal-dialog {
    max-width: 745px;
  }
  .modal-dialog .modal-body {
    padding: 0 50px 50px 50px;
  }
  .component-services-blocks .inner-content >.offset-lg-1 {
    margin-left: 10.33333%;
  }
  .text-only-slide {
    width: 84%;
  }
}
@media only screen and (min-width: 1400px) {
  .text-only-slide {
    width: 90%;
  }
}
@media only screen and (max-width: 991px) {
  .page-template-default .header-text .header-title-wrapper :after {
    bottom: 396px !important;
  }
  .component-header {
    height: 100vh;
  }
  .component-header .header-text {
    padding-right: 15px;
  }
  .component-header .header-text h1 {
    font-size: 40px;
    padding-left: 60px;
  }
  #footer .footer-nav {
    margin-top: 20px;
  }
  #footer .footer-nav ul {
    flex-wrap: wrap;
    justify-content: center;
  }
  #footer .footer-nav ul li:first-child:after {
    content: none;
  }
}
@media only screen and (max-width: 767px) {
  .slick-arrow {
    display: none !important;
  }
  .quote-btn-wrapper {
    margin-top: 400px;
  }
  .admin-sidenav li {
    padding: 8px !important;
  }
  .admin-sidenav li a {
    font-size: 18px;
  }
  .page-template-default .header-text .header-title-wrapper :after {
    bottom: 96px !important;
  }
  .page-template-default .component-header .header-text h1 {
    padding-left: 0;
    margin-left: 0;
  }
  .component-header {
    background-size: cover;
    background-position: left;
  }
  .component-about {
    padding-bottom: 10px;
    padding-top: 10px;
  }
  .component-about .btn-clear {
    display: none;
  }
  .component-about .more-btn {
    position: static;
  }
  .component-text-image {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .component-text-image .component-inner >.row:before {
    width: 100%;
  }
  .component-services-blocks .main-wrapper:after {
    width: 100%;
  }
  .component-services-blocks .inner-block.blank {
    display: none;
  }
  .component-services-blocks .main-wrapper .inner-content {
    bottom: 0;
  }
  .component-services-blocks .main-wrapper .inner-content .blank {
    display: none;
  }
  .component-services-blocks .main-wrapper:after {
    top: -10px;
  }
  .slick-current {
    transform: scale(1);
  }
  .mobile-logo {
    margin-top: 10px;
    margin-left: 10px;
  }
  .component-image-text .more-btn,
  #about .more-btn {
    position: static;
  }
  .carousel-control-next,
  .carousel-control-prev {
    display: none;
  }
}
